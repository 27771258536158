<template>
  <div class="date-picker">
    <date-picker
      v-model="eventDate"
      class="event-days"
      popup-class="multiple-event-date-popup"
      :clearable="false"
      :lang="lang"
      :popup-style="{ zIndex: 9999 }"
      :disabled-date="dt => dt >= disableDates || dt < new Date()"
    />
  </div>
</template>

<script>
import DatePicker from "vue2-datepicker"
import "vue2-datepicker/index.css"
import moment from "moment"
import { mapMutations, mapState } from "vuex"
import { DATE_FORMAT } from "@/types"
import { MAX_EVENTS_WEEKS_DURATION, MAX_WEEKS_DURATION } from "@/defaults"

export default {
  name: "EventDateInput",
  components: {
    DatePicker
  },
  data() {
    return {
      selected: null,
      highlighted: [],
      lang: {
        formatLocale: {
          firstDayOfWeek: 1
        }
      }
    }
  },
  props: {
    date: {
      type: String,
      default: null
    },
    isMultipleEvents: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapState("plan", ["plan"]),
    ...mapState(["profileFacts"]),

    disableDates() {
      const durationLimit = this.isMultipleEvents ? MAX_EVENTS_WEEKS_DURATION : MAX_WEEKS_DURATION
      return new Date(
        moment(this.profileFacts.startDate)
          .add(durationLimit, "weeks")
          .subtract(1, "days")
          .format("YYYY-MM-DD")
      )
    },
    eventDate: {
      get() {
        return this.date ? moment(this.date, DATE_FORMAT).toDate() : null
      },
      set(value) {
        this.$emit("change", moment(value).format(DATE_FORMAT))
      }
    }
  },
  methods: {
    ...mapMutations(["setProfileFactsValue"]),
    getClasses(date) {
      if (this.highlighted.find(v => v.getTime() === date.getTime())) {
        return "plan-start"
      }
      return ""
    }
  }
}
</script>

<style lang="scss">
.event-days {
  &.mx-datepicker {
    width: auto;
  }

  .mx-input-wrapper {
    .mx-input {
      font-size: 16px;
    }
  }
}
.multiple-event-date-popup {
  .mx-calendar-header .mx-btn-icon-double-right,
  .mx-calendar-header .mx-btn-icon-double-left {
    display: none;
  }
}
.plan-start {
  background-color: rgba(45, 175, 0, 0.45) !important;
  color: white !important;
  position: relative;

  &:before {
    display: none;
    position: absolute;
    content: "Your plan starts here";
    width: 120px;
    height: 20px;
    top: -20px;
    left: 100%;
    color: black;
    background-color: #efdede;
  }

  &:hover {
    &:before {
      display: block;
    }
  }
}
</style>
